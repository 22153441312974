exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-order-js": () => import("./../../../src/pages/app/order.js" /* webpackChunkName: "component---src-pages-app-order-js" */),
  "component---src-pages-app-services-js": () => import("./../../../src/pages/app/services.js" /* webpackChunkName: "component---src-pages-app-services-js" */),
  "component---src-pages-app-thanks-js": () => import("./../../../src/pages/app/thanks.js" /* webpackChunkName: "component---src-pages-app-thanks-js" */),
  "component---src-pages-dropoff-js": () => import("./../../../src/pages/dropoff.js" /* webpackChunkName: "component---src-pages-dropoff-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-request-js": () => import("./../../../src/pages/request.js" /* webpackChunkName: "component---src-pages-request-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-track-js": () => import("./../../../src/pages/track.js" /* webpackChunkName: "component---src-pages-track-js" */)
}

